import { defineStore } from 'pinia'
import { ref } from 'vue'
import { useApiCall } from '@/composables/useApiCall'
import { DefaultError } from '@/types/httpError'
import {
  NotificationSuccessResponse,
  NotificationPayload,
  NotificationDeletePayload,
} from '@/types/notification'
import {
  deleteNotificationApiCall,
  notificationApiCall,
} from '@/api/notification'
import { useUXUIStore } from '@/store/uxui'

export const useNotificationStore = defineStore('notification', () => {
  const uxuiStore = useUXUIStore()
  const notificationList = ref<NotificationSuccessResponse['data']>([])
  const errorFields = ref<null | DefaultError['error']>(null)
  const pageNotification = ref(1)
  const {
    data: notificationData,
    // error: notificationError,
    executeApiCall: notificationAction,
  } = useApiCall<
    NotificationSuccessResponse,
    DefaultError,
    NotificationPayload
  >(notificationApiCall, true)

  const {
    data: notificationDeleteData,
    executeApiCall: deleteAction,
    // error: notificationDeleteError,
    // isLoading: isDeleteLoading,
  } = useApiCall<any, DefaultError, NotificationDeletePayload>(
    deleteNotificationApiCall,
    true,
  )

  const notificationDelete = async (id: string) => {
    try {
      await deleteAction({ id })
      if (notificationDeleteData.value) {
        notificationList.value = notificationList.value.filter(
          (item) => item.id !== id,
        )
        uxuiStore.openNotification('done', 'Уведомление удалено')
      }
    } catch (e) {
      console.error(e)
      uxuiStore.openNotification('error', 'Произошла ошибка')
    }
  }

  const writeData = () => {
    if (notificationData.value) {
      notificationList.value = [
        ...notificationList.value,
        ...notificationData.value.data,
      ]
    }
  }

  const getNotificationList = async () => {
    if (notificationData.value) {
      const { meta } = notificationData.value
      if (
        notificationList.value?.length < meta.total &&
        pageNotification.value !== meta.lastPage
      ) {
        pageNotification.value = pageNotification.value + 1
        await notificationAction({ page: pageNotification.value })
        writeData()
      }
    } else {
      await notificationAction({ page: pageNotification.value })
      if (notificationData.value) {
        writeData()
      }
    }
  }

  return {
    notificationList,
    errorFields,
    getNotificationList,
    notificationDelete,
  }
})
