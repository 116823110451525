import { defineComponent, onUnmounted } from 'vue'
import TariffForm from '@/components/TariffForm/TariffForm.vue'
import { useTariffsStore } from '@/store/tariffs'

export default defineComponent({
  name: 'TariffPanel',
  components: {
    TariffForm,
  },
  setup() {
    const tariffsStore = useTariffsStore()
    const onClose = () => {
      tariffsStore.closePanel()
    }

    onUnmounted(() => {
      tariffsStore.selectedTariff = null
    })
    return { onClose }
  },
})
