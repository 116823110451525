import { defineComponent, ref, computed, watch } from 'vue'
import Multiselect from 'vue-multiselect'

export default defineComponent({
  name: 'AppPagination',
  components: {
    Multiselect,
  },
  props: {
    lastPage: {
      type: Number,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
    itemsPerPage: Array<number>,
    isLoading: Boolean,
  },
  emits: ['itemsPerPageChange', 'selectedPageChange'],
  setup(props, { emit }) {
    const selectedItemsPerPageValue = ref<number | null>(null)
    const selectedPage = ref<number>(1)
    const buttonsList = computed(() => {
      const array = []
      let i = 1
      while (i <= props.lastPage) {
        array.push(i)
        i++
      }
      return array
    })

    if (props.itemsPerPage) {
      selectedItemsPerPageValue.value = props.itemsPerPage[0]
    }

    const onPrevBtnClick = () => {
      if (selectedPage.value && selectedPage.value > 1) {
        selectedPage.value = selectedPage.value - 1
        emit('selectedPageChange', selectedPage.value)
      }
    }

    const onNextBtnClick = () => {
      if (selectedPage.value < props.lastPage) {
        selectedPage.value = selectedPage.value + 1
        emit('selectedPageChange', selectedPage.value)
      }
    }

    const onPageButtonClick = (page: number) => {
      if (page !== selectedPage.value) {
        selectedPage.value = page
        emit('selectedPageChange', selectedPage.value)
      }
    }

    watch(
      () => props.currentPage,
      (newVal) => {
        selectedPage.value = newVal
      },
    )

    watch(
      () => selectedItemsPerPageValue.value,
      () => {
        selectedPage.value = 1
        emit('itemsPerPageChange', selectedItemsPerPageValue.value)
      },
    )

    return {
      selectedItemsPerPageValue,
      selectedPage,
      buttonsList,
      onPrevBtnClick,
      onNextBtnClick,
      onPageButtonClick,
    }
  },
})
