export const formatIntMoney = (amount: string): string => {
  const money = amount.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
  return money
}

export const formatDecMoney = (amount: string): string => {
  const parts = amount.split('.')
  const integerPart = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
  const decimalPart = parts[1] === '00' ? '' : `.${parts[1]}`
  return integerPart + decimalPart
}
