import { defineStore } from 'pinia'
import { ref, reactive, computed, watch } from 'vue'
import { ModalContent, ModalNameContent } from '@/types/modals'
import { SelectedPlan } from '@/types/lawsuit'
import { useAuthStore } from '../auth'
import { useLockBodyScroll } from '@/composables/useLockBodyScroll'

export const useUXUIStore = defineStore('uxui', () => {
  const asideCollapsed = ref(true)
  const isAdmin = ref(false)
  const notesPanelVisible = ref(false)
  const notificationVisible = ref(false)
  const typeOfSelectedPlan = ref<SelectedPlan>('task')
  const currentPage = ref<string>('')
  const swipePermission = ref(true)
  const isNotificationOpen = ref(false)
  const eventVisible = ref(false)
  const notification = reactive<{
    text: string
    status: 'done' | 'error' | ''
    size: 'large'
  }>({
    text: '',
    status: 'done',
    size: 'large',
  })
  const { enableBodyScroll, disableBodyScroll } = useLockBodyScroll()
  const authStore = useAuthStore()

  const modalName = ref<ModalNameContent>({
    modalName: '',
    index: undefined as number | undefined,
  })

  const modalContent = ref<ModalContent | null>(null)
  const getIsAdmin = computed(() => isAdmin.value)

  const openNotification = (status: 'done' | 'error', text: string) => {
    notification.status = status
    notification.text = text
    isNotificationOpen.value = true

    setTimeout(closeNotification, 3000)
  }

  const closeNotification = () => {
    isNotificationOpen.value = false
    notification.status = ''
    notification.text = ''
  }

  const switchAside = () => {
    asideCollapsed.value = !asideCollapsed.value
  }

  const setSwipePermission = (payload: boolean) => {
    swipePermission.value = payload
  }

  const removeCollapse = () => {
    asideCollapsed.value = false
  }

  const setAdmin = (payload: boolean) => {
    isAdmin.value = payload
  }

  watch(
    () => authStore.user,
    (newVal) => {
      if (newVal?.roles.includes('super admin')) {
        setAdmin(true)
      }
    },
    { immediate: true },
  )

  const setCurrentPage = (payload: string) => {
    currentPage.value = payload
  }

  const setModalName = (name: string, index?: number) => {
    modalName.value.modalName = name
    if (index) {
      modalName.value.index = index
    }
  }

  const setTypeOfSelectedPlan = (payload: SelectedPlan) => {
    typeOfSelectedPlan.value = payload
  }

  const switchNotesPanel = () => {
    notesPanelVisible.value = !notesPanelVisible.value
  }

  const switchNotificationPanel = () => {
    notificationVisible.value = !notificationVisible.value
  }

  const switchEventPanel = () => {
    eventVisible.value = !eventVisible.value
  }

  const setModalContent = (
    payload: ModalContent | null,
    id: number | null | string,
    params?: any,
  ) => {
    modalContent.value = payload ? { ...payload, id, params } : null
  }

  watch(
    () => [notesPanelVisible.value, modalName.value.modalName],
    ([notesVisible, modalName]) => {
      return notesVisible ||
        !['ConfirmationModal', ''].includes(modalName as string)
        ? disableBodyScroll()
        : enableBodyScroll()
    },
  )

  return {
    asideCollapsed,
    isAdmin,
    notesPanelVisible,
    getIsAdmin,
    isNotificationOpen,
    notification,
    openNotification,
    closeNotification,
    switchAside,
    setAdmin,
    switchNotesPanel,
    modalName,
    setModalName,
    modalContent,
    setModalContent,
    typeOfSelectedPlan,
    setTypeOfSelectedPlan,
    currentPage,
    setCurrentPage,
    removeCollapse,
    setSwipePermission,
    swipePermission,
    switchNotificationPanel,
    notificationVisible,
    switchEventPanel,
    eventVisible,
  }
})
