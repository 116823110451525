<template>
  <AppPanel @close="onCloseBtnClick">
    <div class="trans-panel__header">
      Редактирование транзакции
      <SvgIcon icon="x" @click="onCloseBtnClick" />
    </div>
    <TransactionForm oneColumn />
  </AppPanel>
</template>

<script lang="ts" src="./TransactionPanel.ts"></script>

<style scoped lang="scss" src="./TransactionPanel.scss"></style>
