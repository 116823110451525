import { defineComponent } from 'vue'
import { useCalendarStore } from '@/store/calendar'
import { ViewItem } from '@/types/calendar'
import { useUXUIStore } from '@/store/uxui'
import { useMainStore } from '@/store/main'

export default defineComponent({
  name: 'CalendarViewsMenu',
  setup() {
    const uxuiStore = useUXUIStore()
    const mainStore = useMainStore()
    const calendarStore = useCalendarStore()
    const changeDate = async (type: string) => {
      await calendarStore.changeInitialDate(type)
    }

    const changeView = (view: ViewItem) => {
      calendarStore.changeView(view.value)
    }
    return {
      changeDate,
      changeView,
      calendarStore,
      uxuiStore,
      mainStore,
    }
  },
})
