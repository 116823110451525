<template>
  <div class="search-btn-container">
    <button type="submit">Применить</button>
    <button type="reset" @click="onResetBtnClick">Очистить фильтр</button>
  </div>
</template>

<script lang="ts" src="./SearchButtonsContainer.ts"></script>

<style scoped lang="scss" src="./SearchButtonsContainer.scss"></style>
