<template>
  <div class="star-rating">
    <span
      v-for="star in maxStars"
      :key="star"
      :class="[
        'star',
        {
          green: currentRating <= 3 && star <= currentRating,
          orange: currentRating === 4 && star <= currentRating,
          red: currentRating === 5 && star <= currentRating,
        },
      ]"
      @click="setRating(star)"
    >
      <SvgIcon :icon="'rating'" />
    </span>
  </div>
</template>

<script lang="ts" src="./StarRating.ts"></script>
<style lang="scss" scoped src="./StarRating.scss"></style>
