import { defineComponent, watch, onBeforeMount, onBeforeUnmount } from 'vue'
import { useRouter } from 'vue-router'
import { useLockBodyScroll } from '@/composables/useLockBodyScroll'

export default defineComponent({
  name: 'AppPanel',
  emits: ['close'],
  setup(_, { emit }) {
    const { enableBodyScroll, disableBodyScroll } = useLockBodyScroll()
    const router = useRouter()

    watch(
      () => router.currentRoute.value.name,
      () => {
        emit('close')
      },
    )

    const closeOnEsc = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        emit('close')
      }
    }

    onBeforeMount(async () => {
      disableBodyScroll()
      window.addEventListener('keydown', closeOnEsc)
    })

    onBeforeUnmount(() => {
      window.removeEventListener('keydown', closeOnEsc)
      enableBodyScroll()
    })

    return {}
  },
})
