import {
  defineComponent,
  ref,
  onBeforeMount,
  onBeforeUnmount,
  computed,
} from 'vue'
import { useUXUIStore } from '@/store/uxui'
import { getFormatDate } from '@/helpers/dateFormatter'
import { useEventsStore } from '@/store/events'
import dayjs from 'dayjs'
// import { modalsContent } from '@/config/confirmationModals'

export default defineComponent({
  name: 'EventPanel',
  setup() {
    const eventsStore = useEventsStore()
    const uxuiStore = useUXUIStore()
    const isLoading = ref(false)
    const handleClose = () => {
      uxuiStore.eventVisible = false
      eventsStore.currentEvent = null
    }
    const getStatus = computed(() => {
      return eventsStore.currentEvent?.status !== 'finished'
        ? 'Запланировано'
        : 'Завершено'
    })
    const getDate = computed(() => {
      return eventsStore.currentEvent?.isAllDay
        ? 'Весь день'
        : `с ${dayjs(eventsStore.currentEvent?.since).format('DD.MM.YYYY HH:mm')} по ${dayjs(eventsStore.currentEvent?.till).format('DD.MM.YYYY HH:mm')}`
    })
    // const notificationLoader = ref<HTMLElement | null>(null)

    // const getNotification = async () => {
    //   isLoading.value = true
    //   try {
    //     await notificationStore.getNotificationList()
    //   } catch (e) {
    //     console.error(e)
    //   }
    //   isLoading.value = false
    // }

    // const handleDelete = async (id: string) => {
    //   // await notificationStore.notificationDelete(id)
    //   uxuiStore.setModalName('ConfirmationModal')
    //   uxuiStore.setModalContent(modalsContent['notification'], id)
    // }

    const closeOnEsc = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        handleClose()
      }
    }

    const openEditPopup = () => {
      eventsStore.editEventFormOpen()
    }

    onBeforeMount(async () => {
      // await getNotification()
      window.addEventListener('keydown', closeOnEsc)
    })

    onBeforeUnmount(() => {
      window.removeEventListener('keydown', closeOnEsc)
    })

    return {
      handleClose,
      eventsStore,
      getFormatDate,
      isLoading,
      getDate,
      getStatus,
      openEditPopup,
      // notificationLoader,
      // handleDelete,
    }
  },
})
