import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { useClientsStore } from '@/store/client'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/:catchAll(.*)',
    name: 'not-found',
    component: () => import('@/views/ErrorPage/ErrorPage.vue'),
    meta: {
      layout: 'Simple',
      tKey: 'error.notFound',
    },
  },

  {
    path: '/',
    name: 'root',
    meta: {
      layout: 'Main',
      title: 'Дела',
    },
    children: [
      {
        path: '',
        name: 'home',
        component: () => import('@/views/HomePage/HomePage.vue'),
        meta: {
          layout: 'Main',
        },
      },
      {
        path: 'lawsuit/:id',
        name: 'lawsuit-details',
        component: () => import('@/views/LawsuitDetails/LawsuitDetails.vue'),
        meta: {
          layout: 'Main',
        },
        beforeEnter() {
          // const lawsuitStore = useLawsuitStore()
          // if (lawsuitStore.selectedLawsuit) next()
          // else next(from)
        },
      },
    ],
  },
  {
    path: '/calendar',
    name: 'calendar',
    component: () => import('@/views/CalendarPage/CalendarPage.vue'),
    meta: {
      layout: 'Main',
      title: 'Календарь',
    },
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/views/ProfilePage/ProfilePage.vue'),
    meta: {
      layout: 'Main',
      isCreateWidgetShown: false,
    },
  },
  {
    path: '/clients',
    children: [
      {
        path: '',
        name: 'clients-table',
        component: () => import('@/views/ClientsPage/ClientsPage.vue'),
        meta: {
          layout: 'Main',
          title: 'Клиенты',
        },
      },
      {
        path: ':id',
        name: 'client-details',
        component: () => import('@/views/ClientDetails/ClientDetails.vue'),
        meta: {
          layout: 'Main',
        },
        async beforeEnter(to, from, next) {
          const clientsStore = useClientsStore()
          const id = Number(to.params.id)
          await clientsStore.getClient({ id })
          if (!clientsStore.selectedClient) next(from)
          else next()
        },
      },
    ],
  },

  {
    path: '/finance',
    name: 'finance',
    component: () => import('@/views/FinancePage/FinancePage.vue'),
    meta: {
      layout: 'Main',
      title: 'Финансы',
      isCreateWidgetShown: false,
    },
  },
  {
    path: '/notes',
    name: 'notes',
    component: () => import('@/views/NotesPage/NotesPage.vue'),
    meta: {
      layout: 'Main',
      title: 'Быстрые заметки',
    },
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import('@/views/SettingsPage/SettingsPage.vue'),
    meta: {
      layout: 'Main',
      title: 'Настройки',
      isCreateWidgetShown: false,
    },
  },
  {
    path: '/tasks',
    name: 'tasks',
    component: () => import('@/views/TasksPage/TasksPage.vue'),
    meta: {
      layout: 'Main',
      title: 'Задачи',
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/AuthPage/AuthPage.vue'),
    meta: {
      layout: 'Auth',
    },
  },
  {
    path: '/admin',
    children: [
      {
        path: 'login',
        name: 'adminLogin',
        component: () => import('@/views/AuthPage/AuthPage.vue'),
        meta: {
          layout: 'Auth',
        },
      },
      {
        path: 'recovery',
        name: 'adminRecoveryPassword',
        component: () => import('@/views/AuthPage/AuthPage.vue'),
        meta: {
          layout: 'Auth',
        },
      },
      {
        path: 'home',
        name: 'adminHome',
        component: () => import('@/views/TariffsPage/TariffsPage.vue'),
        meta: {
          layout: 'Main',
          title: 'Тарифы',
        },
      },
      {
        path: 'lawyers',
        name: 'adminLawyers',
        component: () => import('@/views/LawyersPage/LawyersPage.vue'),
        meta: {
          layout: 'Main',
          title: 'Адвокаты',
          isCreateWidgetShown: false,
        },
      },
      {
        path: 'finance',
        name: 'adminFinance',
        component: () => import('@/views/PaymentsPage/PaymentsPage.vue'),
        meta: {
          layout: 'Main',
          title: 'Финансы',
          isCreateWidgetShown: false,
        },
      },
    ],
  },

  {
    path: '/login/recovery',
    name: 'recoveryPassword',
    component: () => import('@/views/AuthPage/AuthPage.vue'),
    meta: {
      layout: 'Auth',
    },
  },
  {
    path: '/login/registration',
    name: 'registration',
    component: () => import('@/views/AuthPage/AuthPage.vue'),
    meta: {
      layout: 'Auth',
    },
  },
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash }
    if (savedPosition) return savedPosition
    return { x: 0, top: 0 }
  },
  routes,
})

export { router, routes }
