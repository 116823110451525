import axios from 'axios'
import {
  TransactionListGetPayload,
  TransactionItemGetDeletePayload,
  TransactionItemCreatePayload,
  TransactionItemEditPayload,
  TransactionSuccessResponse,
  TransactionsSuccessResponse,
} from '@/types/transaction'

export const getTransactionsApiCall = async (
  abortController?: AbortController,
  params?: Partial<TransactionListGetPayload>,
): Promise<TransactionsSuccessResponse> => {
  const response = await axios.post(
    '/v1/transaction/search',
    { ...params },
    { signal: abortController?.signal },
  )

  return response.data
}

export const getTransactionApiCall = async (
  abortController?: AbortController,
  params?: Partial<TransactionItemGetDeletePayload>,
): Promise<TransactionSuccessResponse> => {
  const response = await axios.get(`/v1/transaction/${params?.id}`, {
    signal: abortController?.signal,
  })

  return response.data
}

export const createTransactionApiCall = async (
  abortController?: AbortController,
  params?: Partial<TransactionItemCreatePayload>,
): Promise<TransactionSuccessResponse> => {
  const response = await axios.post(
    '/v1/transaction',
    {
      ...params,
    },
    { signal: abortController?.signal },
  )

  return response.data
}

export const editTransactionApiCall = async (
  abortController?: AbortController,
  params?: Partial<TransactionItemEditPayload>,
): Promise<TransactionSuccessResponse> => {
  const response = await axios.put(
    `/v1/transaction/${params?.id}`,
    {
      ...params?.data,
    },
    { signal: abortController?.signal },
  )

  return response.data
}

export const deleteTransactionApiCall = async (
  abortController?: AbortController,
  params?: TransactionItemGetDeletePayload,
): Promise<TransactionSuccessResponse<''>> => {
  const response = await axios.delete(`/v1/transaction/${params?.id}`, {
    signal: abortController?.signal,
  })

  return response.data
}
