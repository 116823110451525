import axios from 'axios'
import { useAuthStore } from '@/store/auth'

const BASE_URL = import.meta.env.VITE_API_BASE_URL
const USER_LOGIN_COMPLETE_URL = `${BASE_URL}/v1/auth/login/complete`
const ADMIN_LOGIN_COMPLETE_URL = `${BASE_URL}/v1/admin/auth/login/complete`

axios.interceptors.request.use((config) => {
  if (config && config.headers) {
    config.baseURL = BASE_URL
    const authStore = useAuthStore()
    if (authStore.isAuth) {
      config.headers['Authorization'] =
        `${authStore.tokenType} ${authStore.token}`
    }
  }
  return config
})

axios.interceptors.response.use(undefined, async (error) => {
  if (
    !axios.isCancel(error) &&
    [401].includes(error?.response?.status) &&
    ![USER_LOGIN_COMPLETE_URL, ADMIN_LOGIN_COMPLETE_URL].includes(
      error.request.responseURL,
    )
  ) {
    console.log(error?.response?.status)
    window.location.reload()
  }
  return Promise.reject(error)
})
