import axios from 'axios'
import { PaymentGetPayload, PaymentGetResponse } from '@/types/payment'

export const getPaymentsApiCall = async (
  abortController?: AbortController,
  params?: Partial<PaymentGetPayload>,
): Promise<PaymentGetResponse> => {
  const { data } = await axios.post(
    '/v1/admin/payment',
    {
      ...params,
    },
    {
      signal: abortController?.signal,
    },
  )
  return data
}
