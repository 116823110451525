import { defineComponent, ref, onBeforeMount, onBeforeUnmount } from 'vue'
import { useUXUIStore } from '@/store/uxui'
import { getFormatDate } from '@/helpers/dateFormatter'
import { useNotificationStore } from '@/store/notification'
import { useInfinityScroll } from '@/composables/useInfinityScroll'
import { modalsContent } from '@/config/confirmationModals'

export default defineComponent({
  name: 'NotificationPanel',
  setup() {
    const notificationStore = useNotificationStore()
    const uxuiStore = useUXUIStore()
    const isLoading = ref(false)
    const handleClose = () => {
      uxuiStore.switchNotificationPanel()
    }
    const notificationLoader = ref<HTMLElement | null>(null)

    const getNotification = async () => {
      isLoading.value = true
      try {
        await notificationStore.getNotificationList()
      } catch (e) {
        console.error(e)
      }
      isLoading.value = false
    }

    useInfinityScroll(isLoading, notificationLoader, getNotification)

    const handleDelete = async (id: string) => {
      // await notificationStore.notificationDelete(id)
      uxuiStore.setModalName('ConfirmationModal')
      uxuiStore.setModalContent(modalsContent['notification'], id)
    }

    const closeOnEsc = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        handleClose()
      }
    }

    onBeforeMount(async () => {
      await getNotification()
      window.addEventListener('keydown', closeOnEsc)
    })

    onBeforeUnmount(() => {
      window.removeEventListener('keydown', closeOnEsc)
    })

    return {
      handleClose,
      notificationStore,
      getFormatDate,
      isLoading,
      notificationLoader,
      handleDelete,
    }
  },
})
