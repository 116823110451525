import { defineComponent, PropType, computed } from 'vue'
import { TableItems } from '@/types/lawsuit'

export default defineComponent({
  name: 'TableHeadItem',
  props: {
    item: {
      type: Object as PropType<TableItems>,
      required: true,
    },
    openedItem: {
      type: String,
      required: false,
    },
    isActive: {
      type: Boolean,
      required: false,
    },
  },
  emits: ['openItem', 'changeSort'],
  setup(props, { emit }) {
    const isOpen = computed(() => props.openedItem === props.item.key)
    const toggleSort = () => {
      emit('openItem', isOpen.value ? '' : props.item.key)
    }
    const selectSort = (value: string) => {
      emit(
        'changeSort',
        props.item?.key,
        props.item.value === value ? null : value,
      )
      toggleSort()
    }
    const getIcon = computed(() => {
      if (props.item.value) {
        return `sort-${props.item.value}`
      } else {
        return `chevron-${isOpen.value ? 'up' : 'down'}`
      }
    })
    return {
      isOpen,
      toggleSort,
      selectSort,
      getIcon,
    }
  },
})
