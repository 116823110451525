import { defineStore } from 'pinia'
import { useApiCall } from '@/composables/useApiCall'
import {
  getTransactionsApiCall,
  deleteTransactionApiCall,
} from '@/api/transaction'
import { useUXUIStore } from '../uxui'
import { modalsContent } from '@/config/confirmationModals'
import { RootState, RootGetters, RootActions } from './types'
import {
  Transaction,
  TransactionListGetPayload,
  TransactionsSuccessResponse,
  TransactionSuccessResponse,
  TransactionItemGetDeletePayload,
} from '@/types/transaction'
import { DefaultError } from '@/types/httpError'

export const useFinanceStore = defineStore<
  string,
  RootState,
  RootGetters,
  RootActions
>('finance', {
  state: () => {
    return {
      allTransactions: null,
      selectedTransaction: null,
      isTransactionPanelOpen: false,
      searchParams: null,
      currentPage: 1,
      lastPage: 1,
    }
  },
  getters: {
    getTransactionById: (state: RootState) => {
      return (id: number) => {
        return state.allTransactions?.data.find((item) => item.id === id)
      }
    },
    addNewTransaction(state: RootState) {
      return (newTransaction: Transaction) => {
        if (state.allTransactions?.data) {
          state.allTransactions.data = [
            ...state.allTransactions.data,
            newTransaction,
          ]
          return state.allTransactions?.data
        } else {
          return []
        }
      }
    },
    replaceTransaction(state: RootState) {
      return (editedTransaction: Transaction) => {
        if (state.allTransactions?.data) {
          const index = state.allTransactions?.data.findIndex(
            (item) => item.id === editedTransaction.id,
          )
          if (index !== -1) {
            state.allTransactions.data[index] = editedTransaction
          }
          return state.allTransactions.data
        } else {
          return []
        }
      }
    },
  },
  actions: {
    async getTransactions(
      params?: Partial<TransactionListGetPayload>,
      isLazy?: boolean,
    ) {
      const {
        data: transactionList,
        executeApiCall: getTransactionList,
        error: transactionListError,
      } = useApiCall<
        TransactionsSuccessResponse,
        DefaultError,
        Partial<TransactionListGetPayload>
      >(getTransactionsApiCall, true)

      try {
        await getTransactionList({ ...params })
        if (transactionList.value) {
          const { lastPage, currentPage } = transactionList.value.meta
          this.currentPage = currentPage
          this.lastPage = lastPage
          if (isLazy) {
            if (this.currentPage === 1) {
              this.allTransactions = transactionList.value
              return
            }
            if (Array.isArray(this.allTransactions?.data)) {
              this.allTransactions = {
                data: [
                  ...this.allTransactions.data,
                  ...transactionList.value.data,
                ],
                links: transactionList.value.links,
                meta: transactionList.value.meta,
              }
            }

            return
          } else {
            this.allTransactions = transactionList.value
          }
        }
      } catch (error) {
        if (transactionListError.value?.data.error) {
          console.log(transactionListError.value.data.error.error)
        }
      }
    },
    async loadMoreTransactions() {
      if (this.currentPage < this.lastPage) {
        this.currentPage++
        let newParams = { page: this.currentPage }
        if (this.searchParams) {
          newParams = { ...this.searchParams, ...newParams }
        }
        await this.getTransactions(newParams, true)
      }
    },
    async deleteTransaction(id: number) {
      const {
        executeApiCall: deleteTransaction,
        error: deleteTransactionError,
      } = useApiCall<
        TransactionSuccessResponse<''>,
        DefaultError,
        TransactionItemGetDeletePayload
      >(deleteTransactionApiCall, true)

      try {
        await deleteTransaction({ id })
        if (this.allTransactions) {
          this.allTransactions.data = this.allTransactions?.data.filter(
            (item) => item.id !== id,
          )
        }
      } catch (error) {
        if (deleteTransactionError.value?.data.error) {
          console.log(deleteTransactionError.value.data.error.error)
        }
      }
    },
    openRemoveModal(id: number) {
      const uxuiStore = useUXUIStore()
      uxuiStore.setModalName('ConfirmationModal')
      uxuiStore.setModalContent(modalsContent['finance'], id)
    },
    openTransactionPanel() {
      this.isTransactionPanelOpen = true
    },
    closeTransactionPanel() {
      this.isTransactionPanelOpen = false
    },
    openCreateModal() {
      const uxuiStore = useUXUIStore()
      uxuiStore.setModalName('TransactionForm', 7)
    },
    closeCreateModal() {
      const uxuiStore = useUXUIStore()
      uxuiStore.setModalName('')
    },
  },
})
