import { defineStore } from 'pinia'
import { ref, computed } from 'vue'
import { DefaultError, Error, Errors } from '@/types/httpError'
import { useLawsuitStore } from '@/store/lawsuite'
import { useApiCall } from '@/composables/useApiCall'
import {
  eventChangeStatusApiCall,
  eventPartialChangeApiCall,
  deleteEventApiCall,
  eventsTypesListApiCall,
  deleteEventsTypeApiCall,
  eventsTypeChangeApiCall,
  eventsTypeCreateApiCall,
  getEventsApiCall,
  eventsByIdApiCall,
} from '@/api/events'
import {
  EventChangeForm,
  EventChangeFormResponse,
  EventPartialChangeForm,
  EventsTypesResponse,
  EventCategory,
  EventsTypesChangeResponse,
  EventSearchPayload,
  EventSearchData,
  EventByIdPayload,
  EventByIdData,
  EventByIdResponse,
} from '@/types/events'
import { useUXUIStore } from '@/store/uxui'

export const useEventsStore = defineStore('events', () => {
  const uxuiStore = useUXUIStore()
  const lawsuitStore = useLawsuitStore()
  const changeDataResponse = ref(false)
  const errorFields = ref<{ id: number | string; error: Errors }[]>([])
  const partialChangeStatusResponse = ref(false)
  const currentEvent = ref<null | EventByIdData>(null)
  // Формирование данных для настроек
  const eventsTypes = ref<EventCategory[] | null>(null)
  const arrayOfEventsTypes = ref<EventCategory[] | null>([])
  const {
    data: changeEventData,
    executeApiCall: setEventStatus,
    error: eventError,
  } = useApiCall<EventChangeFormResponse, Error, EventChangeForm>(
    eventChangeStatusApiCall,
    true,
  )

  const {
    data: partialChangeEventData,
    executeApiCall: setEventPartialChange,
    error: partialEventError,
  } = useApiCall<EventChangeFormResponse, Error, EventPartialChangeForm>(
    eventPartialChangeApiCall,
    true,
  )

  const { executeApiCall: setDeleteData, error: deleteEventError } = useApiCall<
    EventChangeFormResponse,
    DefaultError,
    EventPartialChangeForm
  >(deleteEventApiCall, true)

  const { data: eventsTypesData, executeApiCall: getEventsTypesData } =
    useApiCall<EventsTypesResponse, DefaultError, EventPartialChangeForm>(
      eventsTypesListApiCall,
      true,
    )
  const {
    data: eventsByIdData,
    isLoading: isEventByIdLoading,
    executeApiCall: getEventsByIdAction,
  } = useApiCall<EventByIdResponse, DefaultError, EventByIdPayload>(
    eventsByIdApiCall,
    true,
  )

  const isCurrentEventLoading = computed(() => isEventByIdLoading.value)

  const { data: deleteEventsType, executeApiCall: setDeleteEventsType } =
    useApiCall<EventsTypesResponse, DefaultError, EventPartialChangeForm>(
      deleteEventsTypeApiCall,
      true,
    )

  const { executeApiCall: setEditEventsType, error: editEventTypeError } =
    useApiCall<EventsTypesChangeResponse, Error, EventCategory>(
      eventsTypeChangeApiCall,
      true,
    )

  const {
    data: createEventsType,
    executeApiCall: setCreateEventsType,
    error: createEventTypeError,
  } = useApiCall<EventsTypesChangeResponse, Error, EventCategory>(
    eventsTypeCreateApiCall,
    true,
  )

  const setDataResponse = (): void => {
    changeDataResponse.value = !changeDataResponse.value
  }

  const setPartialChangeStatusResponse = (): void => {
    partialChangeStatusResponse.value = !partialChangeStatusResponse.value
  }

  const setEventsTypes = (payload: EventCategory[] | null): void => {
    eventsTypes.value = payload
  }

  const setCurrentEvent = async (id: number) => {
    if (currentEvent.value?.id !== id) {
      await setLawsuitEvent(id)
    }
    if (!uxuiStore.eventVisible) {
      uxuiStore.eventVisible = true
    }
  }

  const setLawsuitEvent = async (id: number) => {
    await getEventsByIdAction({ id })
    if (eventsByIdData.value) {
      currentEvent.value = eventsByIdData.value.data
    }
  }

  const setEmptyEventsType = (): void => {
    const emptyEventType: EventCategory = {
      id: 'new' + Date.now(),
      name: '',
      color: '',
      isBillable: false,
      notifyBeforeHours: null,
      markBeforeDays: null,
      createdAt: null,
      updatedAt: null,
      type: null,
    }
    if (eventsTypes.value) {
      eventsTypes.value.push(emptyEventType)
    } else {
      eventsTypes.value = [emptyEventType]
    }
  }

  // Формирование массива с измененными объектами
  const saveEventsTypesObject = (newObject: EventCategory): void => {
    if (arrayOfEventsTypes.value) {
      const index = arrayOfEventsTypes.value.findIndex(
        (item) => item.id === newObject.id,
      )
      if (index !== -1) {
        arrayOfEventsTypes.value[index] = {
          ...arrayOfEventsTypes.value[index],
          ...newObject,
        }
      } else {
        arrayOfEventsTypes.value.push(newObject)
      }
    }
  }

  const removeEventsType = (eventToRemove: EventPartialChangeForm) => {
    if (eventsTypes.value) {
      eventsTypes.value = eventsTypes.value.filter(
        (object) => object.id !== eventToRemove.id,
      )
    }
    if (arrayOfEventsTypes.value) {
      arrayOfEventsTypes.value = arrayOfEventsTypes.value.filter(
        (object) => object.id !== eventToRemove.id,
      )
    }
  }

  const processArrayObjects = async () => {
    errorFields.value = []
    if (Array.isArray(arrayOfEventsTypes.value)) {
      for (const item of arrayOfEventsTypes.value) {
        if (!item.id?.toString().includes('new')) {
          await setChangeEventsTypeById(item)
        } else {
          await cteateEventsTypeObject(item)
        }
      }
    }
    if (!errorFields.value.length) {
      arrayOfEventsTypes.value = []
    }
  }

  const setEventsStatus = async (payload: EventChangeForm) => {
    try {
      await setEventStatus(payload)
      if (changeEventData.value && changeEventData.value.success) {
        lawsuitStore.setChangeStatusInArray(
          changeEventData.value.data.id,
          'event',
          changeEventData.value.data.status,
        )
      }
    } catch {
      if (eventError.value?.data.errors) {
        errorFields.value.push({
          id: payload.id,
          error: eventError.value.data.errors,
        })
      }
    }
  }

  const setPartialEventsChange = async (payload: EventPartialChangeForm) => {
    try {
      await setEventPartialChange(payload)
      if (
        partialChangeEventData.value &&
        partialChangeEventData.value.success
      ) {
        setPartialChangeStatusResponse()
      }
    } catch {
      if (partialEventError.value?.data.errors) {
        errorFields.value.push({
          id: payload.id,
          error: partialEventError.value.data.errors,
        })
      }
    }
  }

  const setDeleteEvent = async (payload: EventPartialChangeForm) => {
    try {
      await setDeleteData(payload)
    } catch {
      if (deleteEventError.value?.data?.error) {
        uxuiStore.openNotification(
          'error',
          (deleteEventError.value.data.error as string) || 'Произошла ошибка',
        )
      }
    }
  }

  const getEventsTypes = async () => {
    try {
      await getEventsTypesData()
      if (eventsTypesData.value && eventsTypesData.value.success) {
        setEventsTypes(eventsTypesData.value.data)
      }
    } catch (e) {
      console.error(e)
    }
  }

  const setDeleteEventsTypeById = async (payload: EventPartialChangeForm) => {
    if (payload && !payload.id?.toString().includes('new')) {
      try {
        await setDeleteEventsType(payload)
        if (deleteEventsType.value && deleteEventsType.value.success) {
          removeEventsType(payload)
        }
      } catch (e) {
        console.error(e)
      }
    } else {
      removeEventsType(payload)
    }
  }

  const setChangeEventsTypeById = async (payload: EventCategory | null) => {
    if (payload) {
      try {
        await setEditEventsType(payload)
      } catch {
        if (editEventTypeError.value?.data.errors) {
          errorFields.value.push({
            id: payload.id,
            error: editEventTypeError.value.data.errors,
          })
        }
      }
    }
  }

  const cteateEventsTypeObject = async (payload: EventCategory) => {
    try {
      await setCreateEventsType(payload)
      if (
        createEventsType.value &&
        createEventsType.value.success &&
        eventsTypes.value
      ) {
        const findItem = eventsTypes.value?.findIndex(
          (item) => item.id === payload.id,
        )
        if (findItem !== -1) {
          eventsTypes.value[findItem] = { ...createEventsType.value.data }
        }
      }
    } catch {
      if (createEventTypeError.value?.data.errors) {
        errorFields.value.push({
          id: payload.id,
          error: createEventTypeError.value.data.errors,
        })
      }
    }
  }

  const {
    data: allEvents,
    executeApiCall: getAllEventsAction,
    error: allTEventsError,
  } = useApiCall<EventSearchData, DefaultError, EventSearchPayload>(
    getEventsApiCall,
    true,
  )

  const eventsList = computed(() => allEvents.value?.data)

  const getAllEvents = async (params?: EventSearchPayload) => {
    const searchParams = {
      ...params,
      isPaginated: false,
    }
    try {
      await getAllEventsAction(searchParams)
    } catch (error) {
      if (allTEventsError.value?.data.error) {
        console.log(allTEventsError.value?.data.error)
      }
    }
  }

  const createEventFormOpen = () => {
    const uxuiStore = useUXUIStore()
    uxuiStore.setModalName('EventForm', 14)
  }
  const editEventFormOpen = () => {
    const uxuiStore = useUXUIStore()
    uxuiStore.setModalName('EventForm', 15)
  }

  const addEvent = async (event: EventByIdData) => {
    console.log(123, event)
  }
  const replaceEvent = (event: EventByIdData) => {
    console.log(123, event)
  }
  const changeEventStatus = async () => {
    console.log(123)
  }

  return {
    setEventsStatus,
    setPartialEventsChange,
    partialChangeStatusResponse,
    setPartialChangeStatusResponse,
    setDeleteEvent,
    eventsTypes,
    getEventsTypes,
    setEventsTypes,
    setDeleteEventsTypeById,
    setChangeEventsTypeById,
    saveEventsTypesObject,
    processArrayObjects,
    arrayOfEventsTypes,
    setEmptyEventsType,
    cteateEventsTypeObject,
    removeEventsType,
    setDataResponse,
    changeDataResponse,
    errorFields,
    eventsList,
    getAllEvents,
    setCurrentEvent,
    currentEvent,
    createEventFormOpen,
    editEventFormOpen,
    addEvent,
    replaceEvent,
    changeEventStatus,
    setLawsuitEvent,
    isCurrentEventLoading,
  }
})
