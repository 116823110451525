import {
  NotificationSuccessResponse,
  NotificationPayload,
  NotificationDeletePayload,
} from '@/types/notification'
import axios from 'axios'

const notificationApiCall = async (
  abortController?: AbortController,
  params?: Partial<NotificationPayload>,
): Promise<NotificationSuccessResponse> => {
  const response = await axios.get(`/v1/notification`, {
    params: { ...params },
    signal: abortController?.signal,
  })
  return response.data
}

const deleteNotificationApiCall = async (
  abortController?: AbortController,
  params?: Partial<NotificationDeletePayload>,
): Promise<any> => {
  const response = await axios.delete(`/v1/notification/${params?.id}`, {
    signal: abortController?.signal,
  })
  return response.data
}

export { notificationApiCall, deleteNotificationApiCall }
