import { defineStore } from 'pinia'
import { computed, reactive, ref, watch } from 'vue'
import { LawsuitForm } from '@/types/lawsuit'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin from '@fullcalendar/timegrid'
import ruLocale from '@fullcalendar/core/locales/ru'
import multiMonthPlugin from '@fullcalendar/multimonth'
import { ViewItem } from '@/types/calendar'
import dayjs from 'dayjs'
import { useEventsStore } from '@/store/events'

export const useCalendarStore = defineStore('calendar', () => {
  const eventsStore = useEventsStore()
  const lawsuitData = ref<LawsuitForm[]>([])
  const currentDate = ref<string>(dayjs(new Date()).format('DD.MM.YYYY'))
  const calendarApi = ref<any>(null)
  const calendarViews = ref<ViewItem[]>([
    {
      title: 'day',
      active: true,
      value: 'timeGridDay',
    },
    {
      title: 'week',
      active: false,
      value: 'timeGridWeek',
    },
    {
      title: 'month',
      active: false,
      value: 'dayGridMonth',
    },
    {
      title: 'year',
      active: false,
      value: 'multiMonthYear',
    },
  ])

  const currentView = computed(() =>
    calendarViews.value.find((item) => item.active),
  )
  const period = computed(() => {
    switch (currentView.value?.title) {
      case 'week':
      case 'month':
      case 'year':
        return [
          dayjs(currentDate.value, 'DD.MM.YYYY')
            .startOf(currentView.value.title)
            .format('DD.MM.YYYY'),
          dayjs(currentDate.value, 'DD.MM.YYYY')
            .endOf(currentView.value.title)
            .format('DD.MM.YYYY'),
        ]
      default:
        return [dayjs(currentDate.value, 'DD.MM.YYYY').format('DD.MM.YYYY')]
    }
  })
  const transformEvents = computed(() => {
    const events = eventsStore.eventsList?.map((event) => {
      return {
        id: event.id,
        title: event.theme,
        start: dayjs(event.since).utc().format('YYYY-MM-DD HH:mm:ss'),
        end: dayjs(event.till).format('YYYY-MM-DD HH:mm:ss'),
        display: 'list-item',
        // allDay: true,
      }
    })
    return events
  })

  const addEvents = () => {
    const events = calendarApi.value.getEvents() as any[]
    if (events.length) {
      events.forEach((item) => {
        item.remove()
      })
    }
    transformEvents.value?.forEach((item) => {
      calendarApi.value.addEvent(item)
    })
  }

  const options = reactive({
    plugins: [
      dayGridPlugin,
      interactionPlugin,
      timeGridPlugin,
      multiMonthPlugin,
    ],
    initialView: 'timeGridDay',
    locale: ruLocale,
    timeZone: 'local',
    initialDate: dayjs(currentDate.value, 'DD.MM.YYYY').format('YYYY-MM-DD'),
    nowIndicator: true,
    contentHeight: 'auto',
    allDaySlot: false,
    headerToolbar: {
      left: calendarViews.value[0].active ? 'title' : '',
      center: '',
      right: '',
    },
    slotDuration: '00:15:00',
    slotLabelInterval: 15,
    slotMinTime: '00:00:00',
    slotMaxTime: '24:00:00',
    slotLabelFormat: {
      hour: '2-digit',
      minute: '2-digit',
      omitZeroMinute: false,
      meridiem: 'short',
    },
    slotMinWidth: 200,
    slotEventOverlap: false,
    events: transformEvents.value,
    eventClick: function (info: {
      event: { id: string; title: string; start: string }
    }) {
      openEvent(Number(info.event.id))
      // info.el.style.borderColor = 'red';
    },
    // eventDisplay: 'list-item',
  })
  const openEvent = (id: number) => {
    eventsStore.setCurrentEvent(id)
  }

  const isListView = ref(false)

  const getApi = (ref: any) => {
    calendarApi.value = ref.getApi()
    addEvents()
  }

  const changeView = (view: string) => {
    if (view === 'list') {
      isListView.value = true
    } else {
      isListView.value = false
      calendarApi.value.changeView(view)
      calendarViews.value.forEach((item) => {
        item.active = item.value === view
      })
    }
  }

  const changeInitialDate = async (type: string) => {
    if (type === 'prev' && currentView.value) {
      currentDate.value = dayjs(currentDate.value, 'DD.MM.YYYY')
        .subtract(1, currentView.value.title)
        .format('DD.MM.YYYY')
    } else if (currentView.value) {
      currentDate.value = dayjs(currentDate.value, 'DD.MM.YYYY')
        .add(1, currentView.value.title)
        .format('DD.MM.YYYY')
    }
  }

  watch(
    () => currentDate.value,
    async () => {
      calendarApi.value.gotoDate(
        dayjs(currentDate.value, 'DD.MM.YYYY').format('YYYY-MM-DD'),
      )
      // await eventsStore.getAllEvents({
      //   sinceStart: '',
      //   sinceEnd: ''
      // })
    },
  )

  watch(
    () => transformEvents.value,
    () => {
      if (calendarApi.value && transformEvents.value) {
        addEvents()
      }
    },
  )

  return {
    lawsuitData,
    options,
    isListView,
    calendarViews,
    changeInitialDate,
    calendarApi,
    getApi,
    changeView,
    currentView,
    period,
    currentDate,
    openEvent,
  }
})
