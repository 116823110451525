import { MenuItemDropdown } from '@/types/createWidget'
import { LawsuitFormObject } from '@/types/lawsuit'

export const lawsuitAuthoritiesMenu: MenuItemDropdown[] = [
  {
    id: 1,
    title: 'Редактировать',
    color: '',
    function: 'editAuthorities',
  },
  {
    id: 3,
    title: 'Удалить',
    color: '#F03810',
    function: 'deleteAuthorities',
  },
]

export const adminMenu = [
  {
    id: 2,
    title: 'Выйти',
    color: '#F03810',
    function: 'logoutHandler',
  },
]

export const userMenu: MenuItemDropdown[] = [
  {
    id: 1,
    title: 'Профиль',
    color: '',
    function: 'profileHandler',
  },
  ...adminMenu,
]

export const LawsuitEmptyObject: LawsuitFormObject = {
  id: null,
  plot: '',
  opponent: '',
  rating: 1,
  contractNumber: null,
  contractSigningDate: null,
  contractValidity: null,
  powerOfAttorney: null,
  powerOfAttorneySigningDate: null,
  powerOfAttorneyValidity: null,
  customerId: null,
  lawsuitCategoryId: 0,
}
