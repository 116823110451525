import { defineComponent, ref, PropType } from 'vue'
import { TableItems } from '@/types/lawsuit'
import TableHeadItem from '@/components/TableHeadItem/TableHeadItem.vue'

export default defineComponent({
  name: 'TableHead',
  components: {
    TableHeadItem,
  },
  props: {
    items: {
      type: Array as PropType<TableItems[]>,
    },
  },
  emits: ['changeSort'],
  setup(props, { emit }) {
    const isOpen = ref(false)
    const openedItem = ref('')
    const toggleSort = () => {
      isOpen.value = !isOpen.value
    }
    const openItem = (key: string) => {
      if (key === openedItem.value) {
        openedItem.value = ''
      } else {
        openedItem.value = key
      }
    }
    const onChangeSort = (key: string, value: string) => {
      emit('changeSort', key, value)
    }
    return {
      isOpen,
      toggleSort,
      onChangeSort,
      openedItem,
      openItem,
    }
  },
})
