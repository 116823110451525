<template>
  <div @click="toggleSort" class="table-head__item">
    <p class="table-head__item-text">
      {{ item.title }}
      <span class="table-head__item-span" v-if="item.important">*</span>
    </p>
    <SvgIcon
      v-if="item.sortable && item.key"
      :icon="getIcon"
      :class="['table-head__item-icon', { active: item.value }]"
    />
    <div v-show="isOpen && item.sortable && item.key" class="table-head__menu">
      <div
        @click="selectSort(sortItem.value)"
        v-for="sortItem in item.items"
        :key="sortItem.value"
        class="table-head__menu-item"
      >
        <SvgIcon
          :icon="`sort-${sortItem.value}`"
          class="table-head__menu-icon"
        />
        <span class="table-head__menu-text">{{ sortItem.title }}</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts" src="./TableHeadItem.ts"></script>
<style lang="scss" scoped src="./TableHeadItem.scss"></style>
