<template>
  <div>
    <span v-if="title" class="select__title">{{ title }}</span>
    <Multiselect
      id="selected"
      v-model="selected"
      :options="options"
      :label="label"
      :searchable="searchable"
      :clear-on-select="false"
      :allow-empty="allowEmpty"
      :placeholder="placeholder"
      :close-on-select="closeOnSelect"
      :show-labels="false"
      :multiple="multiple"
      :track-by="objectKey !== 'lawsuit' ? 'name' : 'opponent'"
      :option-height="104"
      @open="handleOpen"
      @close="handleClose"
    >
      <template #noOptions>
        <span>Список пуст</span>
      </template>
      <template #noResult>
        <span>Не найдено</span>
      </template>
      <template #singleLabel="props">
        <div class="select__option-wrapper">
          <span
            v-if="props.option.color && typeof props.option.color === 'string'"
            :style="{ backgroundColor: props.option.color }"
            class="select__option-span"
          >
          </span>
          {{
            props.option && objectKey === 'lawsuit'
              ? `${props.option.lawsuitCategory.name} ${props.option.opponent}`
              : objectKey === 'userTariff'
                ? props.option.label
                : props.option.name
          }}
        </div>
      </template>

      <template #option="props">
        <div class="select__option-wrapper">
          <span
            v-if="props.option.color && typeof props.option.color === 'string'"
            :style="{ backgroundColor: props.option.color }"
            class="select__option-span"
          >
          </span>
          {{
            props.option && objectKey === 'lawsuit'
              ? `${props.option.lawsuitCategory.name} ${props.option.opponent}`
              : objectKey === 'userTariff'
                ? props.option.label
                : props.option.name
          }}
        </div>
      </template>
      <template #selection="{ values }">
        <span class="multiselect__single" v-if="values.length"
          >Выбраных тэгов {{ values.length }}</span
        >
      </template>
      <template #caret>
        <SvgIcon icon="chevron-down" class="caret" />
      </template>
    </Multiselect>
  </div>
</template>

<script lang="ts" src="./SelectElement.ts"></script>
<style lang="scss" src="./SelectElement.scss"></style>
