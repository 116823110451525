<template>
  <div class="main">
    <!--    header-->
    <NavBar />
    <AsidePanel />
    <transition name="notes">
      <component :is="currentPanel" />
    </transition>
    <component
      v-if="uxuiStore.modalName.modalName !== ''"
      :is="ModalLayer"
      :class="uxuiStore.modalName.modalName"
    >
      <template
        #modal__header
        v-if="uxuiStore.modalName.modalName !== 'ConfirmationModal'"
      >
        <ModalHeader :index="uxuiStore.modalName?.index" />
      </template>
    </component>
    <main>
      <slot />
    </main>
    <CalendarViewsMenu v-if="isCalendarPage" />
    <NotificationsComponent
      v-if="!isMobile && isNotificationOpen"
      :text="notification.text"
      :status="notification.status"
      :size="notification.size"
      :is-in-main="true"
      class="main-layout"
    />
    <EventPanel v-if="uxuiStore.eventVisible" />
    <NotificationPanel v-if="!isMobile && uxuiStore.notificationVisible" />
    <CreateWidget v-show="isCreateWidgetShown" />
    <!--    footer-->
  </div>
</template>

<script lang="ts" src="./MainLayout.ts"></script>
<style lang="scss" scoped src="./MainLayout.scss"></style>
