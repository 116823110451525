<template>
  <div class="pagination">
    <div class="pagination__buttons">
      <button type="button" class="button button_arrow" @click="onPrevBtnClick">
        <SvgIcon icon="chevron-left" />
      </button>
      <button
        v-for="button of buttonsList"
        :key="button"
        type="button"
        :class="['button', { button_active: selectedPage === button }]"
        @click="onPageButtonClick(button)"
      >
        {{ button }}
      </button>
      <button type="button" class="button button_arrow" @click="onNextBtnClick">
        <SvgIcon icon="chevron-right" />
      </button>
    </div>
    <div v-if="itemsPerPage?.length" class="pagination__second">
      <div class="pagination__select">
        Показывать по:
        <Multiselect
          v-model="selectedItemsPerPageValue"
          :options="itemsPerPage"
          :searchable="false"
          placeholder=""
          :allow-empty="false"
          selectLabel=""
          selectedLabel=""
          deselectLabel=""
        >
          <template #caret>
            <SvgIcon icon="chevron-down" class="pagination__caret" />
          </template>
        </Multiselect>
      </div>
      <slot />
    </div>
  </div>
</template>

<script lang="ts" src="./AppPagination.ts"></script>

<style lang="scss" src="./AppPagination.scss"></style>
