<template>
  <AppPanel class="tariff-panel" @close="onClose">
    <div class="tariff-panel__header">
      <h3>Тариф</h3>
      <SvgIcon icon="x" @click="onClose" />
    </div>
    <TariffForm />
  </AppPanel>
</template>

<script lang="ts" src="./TariffPanel.ts"></script>

<style scoped lang="scss" src="./TariffPanel.scss"></style>
