<template>
  <AppPanel @close="handleClose">
    <div class="event-panel__header">
      <button class="event-panel__header-close" @click="handleClose">
        <SvgIcon icon="x" />
      </button>
    </div>
    <div v-if="eventsStore.currentEvent" class="event-panel__body">
      <div class="event-panel__wrapper">
        <span class="event-panel__title">Тема события</span>
        <div class="event-panel__theme">
          <span class="event-panel__theme-value">{{
            eventsStore.currentEvent.theme
          }}</span>
          <SvgIcon icon="edit" class="event-panel__edit" />
        </div>
      </div>
      <div class="event-panel__wrapper">
        <span class="event-panel__title">Место события</span>
        <span class="event-panel__value">{{
          eventsStore.currentEvent.theme
        }}</span>
      </div>
      <div class="event-panel__wrapper">
        <span class="event-panel__title">Дата/время</span>
        <div class="event-panel__calendar">
          <span class="event-panel__value">{{ getDate }}</span>
          <button class="event-panel__calendar-button">
            <span>Добавить в календарь</span>
            <SvgIcon icon="plus" />
          </button>
        </div>
      </div>
      <div class="event-panel__wrapper">
        <span class="event-panel__title">Клиент</span>
        <span class="event-panel__value">{{
          eventsStore.currentEvent.customer?.name || '-'
        }}</span>
      </div>
      <div class="event-panel__wrapper">
        <span class="event-panel__title">Тип события</span>
        <div class="event-panel__category">
          <div
            class="event-panel__category-color"
            :style="{
              background: `${eventsStore.currentEvent.lawsuitEventCategory.color}`,
            }"
          ></div>
          <span class="event-panel__value">{{
            eventsStore.currentEvent.lawsuitEventCategory.name
          }}</span>
          <span
            v-if="eventsStore.currentEvent.status"
            class="event-panel__category-status"
            >{{ getStatus }}</span
          >
        </div>
      </div>
      <div v-if="eventsStore.currentEvent.lawsuit" class="event-panel__wrapper">
        <span class="event-panel__title">Дело</span>
        <span class="event-panel__value">{{
          eventsStore.currentEvent.lawsuit.hintText || '-'
        }}</span>
      </div>
      <div v-if="eventsStore.currentEvent.lawsuit" class="event-panel__wrapper">
        <span class="event-panel__title">№ дела</span>
        <router-link
          v-if="eventsStore.currentEvent.lawsuit.authorities?.length"
          to=""
          class="event-panel__value-link"
          >{{
            eventsStore.currentEvent.lawsuit.authorities[0].lawsuitNumber
          }}</router-link
        >
        <span v-else class="event-panel__value-link">-</span>
      </div>
      <div class="event-panel__wrapper">
        <span class="event-panel__title">Стоимость консультации, руб</span>
        <span class="event-panel__value">{{
          eventsStore.currentEvent.cost || '-'
        }}</span>
      </div>
      <div
        v-if="eventsStore.currentEvent?.lawsuit?.authorities?.length"
        class="event-panel__wrapper"
      >
        <span class="event-panel__title">Орган рассмотрения</span>
        <div class="event-panel__calendar">
          <span class="event-panel__value">{{
            eventsStore.currentEvent?.lawsuit?.authorities[0].judge
          }}</span>
          <button class="event-panel__calendar-button">
            <span
              >Все органы рассмотрения
              <!--              ({{-->
              <!--                eventsStore.currentEvent?.lawsuit?.authoritiesCount-->
              <!--              }})-->
            </span>
            <SvgIcon icon="chevron-right" />
          </button>
        </div>
      </div>
      <div
        v-if="
          eventsStore.currentEvent?.lawsuit?.contractNumber ||
          eventsStore.currentEvent?.lawsuit?.powerOfAttorney
        "
        class="event-panel__agreement"
      >
        <span class="event-panel__agreement-title">Договор</span>
        <div class="event-panel__wrapper">
          <span class="event-panel__title">Номер</span>
          <span class="event-panel__value">{{
            eventsStore.currentEvent?.lawsuit?.contractNumber || '-'
          }}</span>
        </div>
        <div class="event-panel__wrapper">
          <span class="event-panel__title">Срок действия</span>
          <span class="event-panel__value">{{
            eventsStore.currentEvent?.lawsuit?.contractValidity || '-'
          }}</span>
        </div>
        <span class="event-panel__agreement-title">Доверенность</span>
        <div class="event-panel__wrapper">
          <span class="event-panel__title">Номер</span>
          <span class="event-panel__value">{{
            eventsStore.currentEvent?.lawsuit?.powerOfAttorney || '-'
          }}</span>
        </div>
        <div class="event-panel__wrapper">
          <span class="event-panel__title">Действует до</span>
          <span class="event-panel__value">{{
            eventsStore.currentEvent?.lawsuit?.powerOfAttorneyValidity || '-'
          }}</span>
        </div>
      </div>
      <div class="event-panel__wrapper">
        <span class="event-panel__title">Примечание</span>
        <span class="event-panel__value">{{
          eventsStore.currentEvent?.comment || '-'
        }}</span>
      </div>
      <article class="event-panel__event">
        <span class="event-panel__title">Связанная задача</span>
        <div class="event-panel__event-empty">
          <span class="event-panel__value">Связанная задача отсутствует</span>
          <button type="button" class="event-panel__event-add-btn">
            Добавить
            <SvgIcon icon="plus" />
          </button>
        </div>
      </article>
    </div>
    <div
      v-if="eventsStore.isCurrentEventLoading"
      class="event-panel__loader"
    ></div>
    <div class="event-panel__actions">
      <router-link
        v-if="eventsStore.currentEvent?.lawsuit"
        :to="{
          name: 'lawsuit-details',
          params: { id: eventsStore.currentEvent.lawsuit.id },
        }"
        class="event-panel__actions-link"
        >Перейти в досье</router-link
      >
      <div class="event-panel__actions-wrapper">
        <button @click="openEditPopup" class="event-panel__actions-edit">
          Редактировать
        </button>
        <button class="event-panel__actions-delete">Отменить событие</button>
      </div>
    </div>
  </AppPanel>
</template>

<script lang="ts" src="./EventPanel.ts"></script>
<style lang="scss" scoped src="./EventPanel.scss"></style>
