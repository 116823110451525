import { defineStore } from 'pinia'
import { useApiCall } from '@/composables/useApiCall'
import { getPaymentsApiCall } from '@/api/payment'
import { RootState, RootGetters, RootActions } from './type'
import { PaymentGetPayload, PaymentGetResponse } from '@/types/payment'
import { DefaultError } from '@/types/httpError'

export const usePaymentStore = defineStore<
  string,
  RootState,
  RootGetters,
  RootActions
>('payment', {
  state() {
    return {
      allPayments: null,
      searchParams: null,
      currentPage: 1,
      lastPage: 1,
      error: null,
    }
  },
  getters: {
    getStoredPayments(state) {
      return () => state.allPayments
    },
  },
  actions: {
    async getPayments(params: Partial<PaymentGetPayload>, isLazy?: boolean) {
      const {
        data: allPaymentsData,
        executeApiCall: getPayments,
        error: allPaymentsError,
      } = useApiCall<
        PaymentGetResponse,
        DefaultError,
        Partial<PaymentGetPayload>
      >(getPaymentsApiCall, true)

      if (params) {
        this.searchParams = { ...this.searchParams, ...params }
      }

      try {
        await getPayments(params)
        if (allPaymentsData.value) {
          const { lastPage, currentPage } = allPaymentsData.value.meta
          this.currentPage = currentPage
          this.lastPage = lastPage
          if (isLazy) {
            if (this.currentPage === 1) {
              this.allPayments = allPaymentsData.value.data
              return
            }
            if (Array.isArray(this.allPayments)) {
              this.allPayments = [
                ...this.allPayments,
                ...allPaymentsData.value.data,
              ]
            }

            // return
          } else {
            this.allPayments = allPaymentsData.value.data
          }
        }
      } catch (error) {
        if (allPaymentsError.value?.data) {
          this.error = allPaymentsError.value.data.error
        }
      }
    },
    async loadMorePayments() {
      if (this.currentPage < this.lastPage) {
        this.currentPage++
        let newParams = { page: this.currentPage }
        if (this.searchParams) {
          newParams = { ...this.searchParams, ...newParams }
        }
        await this.getPayments(newParams, true)
      }
    },
  },
})
