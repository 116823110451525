<template>
  <form
    :class="['tariff-form', { modal: isTariffNew }]"
    @submit.prevent="submitHandler"
  >
    <fieldset class="tariff-form__fieldset">
      <span class="label-text">
        Наименование тарифа<span class="required">*</span>
      </span>
      <FormElement
        v-model:value="fields.label"
        tagName="input"
        placeholder="Введите наименование тарифа"
        :maxLength="255"
        :errorText="validationErrors?.label"
      />
    </fieldset>
    <fieldset class="tariff-form__fieldset">
      <span class="label-text">
        Сумма, руб<span class="required">*</span>
      </span>
      <FormElement
        v-model:value="fields.cost"
        tagName="input"
        v-maska
        data-maska-number-locale="ru"
        data-maska-number-unsigned
        :maxLength="10"
        placeholder="Введите сумму"
        :errorText="validationErrors?.cost"
      />
    </fieldset>
    <fieldset class="tariff-form__fieldset">
      <span class="label-text">Статус</span>
      <label
        v-for="{ label, value } of STATUSES"
        :key="value"
        :class="[
          'tariff-form__money-input',
          { active: fields.status === value },
        ]"
      >
        <input
          type="radio"
          :value="value"
          @click="onValueChange('status', value)"
        />
        {{ label }}
      </label>
    </fieldset>
    <fieldset class="tariff-form__fieldset">
      <span class="label-text">Комментарий</span>
      <FormElement
        v-model:value="fields.comment"
        tagName="textarea"
        placeholder="Введите комментарий"
        :maxLength="255"
        :errorText="validationErrors?.comment"
      />
    </fieldset>
    <fieldset class="tariff-form__fieldset">
      <span class="label-text"
        >Доступные разделы<span class="required">*</span></span
      >
      <label
        v-for="{ label, value, key } of managePermissionsArray"
        :key="key"
        :class="[
          'tariff-form__checkbox',
          {
            checked: value && fields.permissionIds?.includes(value),
          },
        ]"
      >
        <input type="checkbox" @click="onValueChange('permissionIds', value)" />
        {{ label }}
      </label>
    </fieldset>
    <NotificationsComponent
      v-show="mainError"
      :text="mainError"
      size="small"
      status="error"
    />
    <button
      :class="['tariff-form__submit-btn', { new: isTariffNew }]"
      type="submit"
      :disabled="isSubmitBtnDisabled"
    >
      {{ isTariffNew ? 'Создать тариф' : 'Сохранить' }}
    </button>
  </form>
</template>

<script lang="ts" src="./TariffForm.ts"></script>

<style scoped lang="scss" src="./TariffForm.scss"></style>
