import { defineStore } from 'pinia'
import { ref } from 'vue'
import { DefaultError, Errors, Error } from '@/types/httpError'
import { useApiCall } from '@/composables/useApiCall'
import {
  getTagsForTasksApiCall,
  createTagForTaskApiCall,
  editTagForTaskApiCall,
  deleteTagForTaskApiCall,
} from '@/api/tasks'
import {
  TagForTaskResponse,
  TagForTasksResponse,
  TagForTasksForm,
  TagForTasks,
} from '@/types/tasks'
import { useUXUIStore } from '@/store/uxui'

export const useTagsStore = defineStore('tags', () => {
  const errorFields = ref<{ id: number | string; error: Errors }[]>([])
  const tagsList = ref<TagForTasks[] | null>(null)
  const arrayOfTags = ref<TagForTasks[] | null>([])
  const uxuiStore = useUXUIStore()

  const setEmptyTag = (): void => {
    const emptyTag: TagForTasks = {
      id: 'new' + Date.now(),
      name: '',
      color: '',
      createdAt: null,
      updatedAt: null,
    }
    if (tagsList.value) {
      tagsList.value.push(emptyTag)
    } else {
      tagsList.value = [emptyTag]
    }
  }

  // Формирование массива с измененными объектами
  const saveTagObject = (newObject: TagForTasks): void => {
    if (arrayOfTags.value) {
      const index = arrayOfTags.value.findIndex(
        (item) => item.id === newObject.id,
      )
      if (index !== -1) {
        arrayOfTags.value[index] = { ...arrayOfTags.value[index], ...newObject }
      } else {
        arrayOfTags.value.push(newObject)
      }
    }
  }

  const processArrayObjects = async () => {
    errorFields.value = []
    if (Array.isArray(arrayOfTags.value)) {
      for (const item of arrayOfTags.value) {
        if (!item.id?.toString().includes('new')) {
          await editTagsForTasks(item)
        } else {
          await createTagsForTasks(item)
        }
      }
    }
    if (!errorFields.value.length) {
      arrayOfTags.value = []
    }
  }

  const replaceLastItem = (newItem: TagForTasks) => {
    if (tagsList.value && tagsList.value.length > 0) {
      tagsList.value[tagsList.value.length - 1] = newItem
    }
  }

  const {
    data: tagsForTasksData,
    executeApiCall: getTagsForTasksData,
    error: tagsForTasksError,
  } = useApiCall<TagForTasksResponse, DefaultError, TagForTasksForm>(
    getTagsForTasksApiCall,
    true,
  )

  const {
    data: createTagsForTasksData,
    executeApiCall: createTagsData,
    error: createTagError,
  } = useApiCall<TagForTaskResponse, Error, TagForTasks>(
    createTagForTaskApiCall,
    true,
  )

  const {
    // data: editTagsForTasksData,
    executeApiCall: editTagsData,
    error: editTagError,
  } = useApiCall<TagForTaskResponse, Error, TagForTasks>(
    editTagForTaskApiCall,
    true,
  )

  const {
    data: deleteTagsForTasksData,
    executeApiCall: deleteTagsData,
    error: deleteTagError,
  } = useApiCall<TagForTaskResponse, DefaultError, TagForTasksForm>(
    deleteTagForTaskApiCall,
    true,
  )

  const setTagList = (payload: TagForTasks[]) => {
    tagsList.value = payload
  }

  const removeTaskFromList = (objectToRemove: TagForTasksForm) => {
    if (tagsList.value) {
      tagsList.value = tagsList.value.filter(
        (object) => object.id !== objectToRemove.id,
      )
    }
  }

  const getTagsForTasksList = async () => {
    try {
      await getTagsForTasksData()
      if (tagsForTasksData.value) {
        setTagList(tagsForTasksData.value.data)
      }
    } catch {
      uxuiStore.openNotification(
        'error',
        (tagsForTasksError.value?.data.error as string) || 'Произошла ошибка',
      )
    }
  }

  const createTagsForTasks = async (payload: TagForTasks) => {
    try {
      await createTagsData(payload)
      if (
        createTagsForTasksData.value &&
        createTagsForTasksData.value.success
      ) {
        replaceLastItem(createTagsForTasksData.value.data)
      }
    } catch {
      if (createTagError.value?.data.errors) {
        errorFields.value.push({
          id: payload.id,
          error: createTagError.value.data.errors,
        })
      }
    }
  }

  const editTagsForTasks = async (payload: TagForTasks) => {
    try {
      await editTagsData(payload)
    } catch {
      if (editTagError.value?.data.errors) {
        errorFields.value.push({
          id: payload.id,
          error: editTagError.value.data.errors,
        })
      }
    }
  }

  // TagForTasks
  const deleteTagsForTasks = async (payload: TagForTasksForm) => {
    if (payload && !payload.id.toString().includes('new')) {
      try {
        await deleteTagsData(payload)
        if (
          deleteTagsForTasksData.value &&
          deleteTagsForTasksData.value.success
        ) {
          if (payload) {
            removeTaskFromList(payload)
          }
        }
      } catch {
        uxuiStore.openNotification(
          'error',
          (deleteTagError.value?.data.error as string) || 'Произошла ошибка',
        )
      }
    } else {
      removeTaskFromList(payload)
    }
  }

  return {
    getTagsForTasksList,
    tagsList,
    setTagList,
    createTagsForTasks,
    setEmptyTag,
    saveTagObject,
    processArrayObjects,
    deleteTagsForTasks,
    arrayOfTags,
    errorFields,
  }
})
