import { defineComponent } from 'vue'
import TransactionForm from '@/components/TransactionForm/TransactionForm.vue'
import { useFinanceStore } from '@/store/finance'

export default defineComponent({
  name: 'TransactionPanel',
  components: {
    TransactionForm,
  },
  setup() {
    const financeStore = useFinanceStore()

    const onCloseBtnClick = () => {
      financeStore.selectedTransaction = null
      financeStore.closeTransactionPanel()
    }
    return { onCloseBtnClick }
  },
})
